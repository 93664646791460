@import url('https://fonts.googleapis.com/css?family=Inter:regular,bold,italic&subset=latin,latin-ext');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --background: 0 0% 98%; /* Light zinc tone */
    --foreground: 210 22% 20%; /* Darker zinc */

    --muted: 210 16% 90%; /* Light zinc tone */
    --muted-foreground: 210 10% 40%; /* Slightly muted zinc */

    --popover: 0 0% 98%; /* Same as background */
    --popover-foreground: 210 22% 20%; /* Dark zinc foreground */

    --border: 210 10% 85%; /* Light zinc for borders */
    --input: 210 10% 85%; /* Match input and border for consistency */

    --card: 0 0% 98%; /* Same as background */
    --card-foreground: 210 22% 20%; /* Dark zinc for text */

    --primary: 202 96% 45%; /* Sky blue */
    --primary-foreground: 0 0% 100%; /* White foreground for sky blue */

    --secondary: 210 16% 90%; /* Lighter zinc tone for secondary */
    --secondary-foreground: 210 22% 20%; /* Dark zinc for contrast */

    --accent: 202 96% 45%; /* Sky blue as the accent */
    --accent-foreground: 0 0% 100%; /* White text on accent */

    --destructive: 0 100% 50%; /* Bright red for destructive actions */
    --destructive-foreground: 0 0% 100%; /* White foreground */

    --ring: 202 96% 45%; /* Sky blue ring */

    --radius: 0.5rem;
  }

  .dark {
    --background: 210 15% 15%; /* Darker zinc background */
    --foreground: 210 10% 85%; /* Light zinc for text */

    --muted: 210 10% 25%; /* Dark muted zinc */
    --muted-foreground: 210 10% 70%; /* Lighter zinc for muted text */

    --accent: 202 96% 45%; /* Sky blue */
    --accent-foreground: 210 10% 85%; /* Light zinc text */

    --popover: 210 5% 7%; /* Darker zinc for secondary */
    --popover-foreground: 210 10% 85%; /* Light zinc for text */

    --border: 210 10% 25%; /* Dark zinc for borders */
    --input: 210 10% 25%; /* Match border and input */

    --card: 210 15% 15%; /* Same as background */
    --card-foreground: 210 10% 85%; /* Light zinc text */

    --primary: 202 96% 45%; /* Sky blue */
    --primary-foreground: 0 0% 100%; /* White text */

    --secondary: 210 10% 30%; /* Darker zinc for secondary */
    --secondary-foreground: 210 10% 85%; /* Lighter zinc text */

    --destructive: 0 83% 61%; /* Dark red */
    --destructive-foreground: 0 0% 100%; /* White text */

    --ring: 202 96% 45%; /* Sky blue ring */

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

.dark .app-logo.sidebar-collapsed [fill='white'] {
  display: none;
}

.app-logo.sidebar-collapsed [fill='#09090B'] {
  display: none;
}

.code-editor .cm-theme .cm-editor,
.code-editor .cm-theme .cm-gutters {
  background: transparent !important;
  outline: none !important;
}

.react-flow {
  background: transparent !important;
}
